import React from 'react';
import {useField} from 'formik';
import {TextField} from '@material-ui/core';

const MuiTextField = ({label, ...props}) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      fullWidth
      label={label}
      variant='outlined'
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

export default MuiTextField;
