import React, {useState} from 'react';
import Paper from '@material-ui/core/Paper';
import {Form, Formik, FormikErrors} from 'formik';

import {useAuth} from '../../contexts/AuthContext';
import Typography from '@material-ui/core/Typography';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import {Box, Button, CircularProgress} from '@material-ui/core';
import MuiTextField from '../misc/MuiTextField';

const Login: React.FC = () => {
  const {login, loading} = useAuth();
  const [alertMessage, setAlertMessage] = useState('');
  const [isErrorMessage, setIsErrorMessage] = useState(true);
  const [alertSnackbar, setAlertSnackbar] = useState(false);
  const navigate = useNavigate();

  interface IValues {
    email: string;
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validate={(values) => {
          const errors: FormikErrors<IValues> = {};
          if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          try {
            await login(values.email, values.password);
            setIsErrorMessage(false);
            setAlertMessage(
                'Congratulations, your account has been successfully created',
            );
            navigate('/');
          } catch (e: unknown) {
            if (e instanceof Error) {
              setAlertMessage(e.message);
            } else {
              setAlertMessage('An unknown error occurred');
            }
            setAlertSnackbar(true);
          } finally {
            setAlertSnackbar(true);
          }
        }}
      >

        {({submitForm}) => (
          <Form>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              height='100vh'
            >
              <Box width={{xs: '100%', sm: 400}}>
                <Paper elevation={2} >
                  <Box
                    width={{xs: '100%', sm: 400}}
                    pt={4}
                    pb={4}
                    px={4}
                  >
                    <Typography variant='h5' align='center'>
                      Log In
                    </Typography>
                    <Typography color='textSecondary'
                      variant='body2' align='center'>
                      Enter your email.<br />
                      Enter your password.<br />
                      It&apos;s magical!
                    </Typography>
                    <Box p={2}>

                      <MuiTextField
                        fullWidth
                        label='Email'
                        variant='outlined'
                        name='email'
                      />
                    </Box>

                    <Box p={2}>
                      <MuiTextField
                        fullWidth
                        name='password'
                        label='Password'
                        variant='outlined'
                        type='password'

                      />
                    </Box>


                    {loading &&
                      <Box p={2} display='flex' justifyContent='center'>
                        <CircularProgress />
                      </Box>}

                    {alertMessage &&
                      <Snackbar
                        open={alertSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setAlertSnackbar(false)}
                      >
                        {isErrorMessage ?
                          <Alert severity={'error'}>{alertMessage}</Alert> :
                          <Alert severity={'success'}>{alertMessage}</Alert>
                        }
                      </Snackbar>
                    }


                    <Box p={2}>
                      <Button
                        fullWidth
                        color='primary'
                        variant='contained'
                        disabled={loading}
                        onClick={submitForm}
                      >
                        Login
                      </Button>
                    </Box>

                    <Box display='flex' justifyContent='center'>
                      <Link component={RouterLink} to='/forgot-password'>
                        <Typography variant='body1'>
                          Forgot Password?
                        </Typography>
                      </Link>
                    </Box>

                    <Box p={2}>
                      <Divider />
                    </Box>

                    <Box
                      alignItems='center'
                      display='flex'
                      justifyContent='center'
                    >
                      <Typography variant='body1' >
                        Don&apos;t have an Account?&nbsp;
                        <Link component={RouterLink} to='/signup'>
                          Sign Up
                        </Link>
                      </Typography>
                    </Box>
                  </Box>

                </Paper>
              </Box>


            </Box>
          </Form>
        )}
      </Formik>

    </>
  );
};

export default Login;
