import React, {useState} from 'react';
import {useFormik} from 'formik';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Snackbar,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {useAuth} from '../../contexts/AuthContext';
import {functions} from '../../api/firebase';
import * as yup from 'yup';
import Link from '@material-ui/core/Link';
import {Link as RouterLink} from 'react-router-dom';
import {Alert} from '@material-ui/lab';
import {httpsCallable} from 'firebase/functions';


const validationSchema = yup.object({
  email: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
});

const AdminPanel: React.FC = () => {
  const {currentUser} = useAuth();
  const [alertMessage, setAlertMessage] = useState('');
  const [isErrorMessage, setIsErrorMessage] = useState(true);
  const [alertSnackbar, setAlertSnackbar] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const addAdminRole = httpsCallable<{
        email: string;
      }, {
         error: string
        } | {
           message: string
           } | { errorInfo: { message: string } }>(functions, 'addAdminRole');
      try {
        const {data} = await addAdminRole({email: values.email});

        if ('error' in data) {
          setIsErrorMessage(true);
          setAlertMessage(data.error);
        } else if ('message' in data) {
          setIsErrorMessage(false);
          setAlertMessage(data.message);
        } else {
          setIsErrorMessage(true);
          setAlertMessage(data.errorInfo.message);
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          setAlertMessage(e.message);
        } else {
          setAlertMessage('An unknown error occurred');
        }
        setAlertSnackbar(true);
      } finally {
        setAlertSnackbar(true);
      }
    },
  });

  return (
    <>
      {currentUser.admin === true ?
        <div>
          <form onSubmit={formik.handleSubmit}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              height='100vh'
            >
              <Box width={{xs: '100%', sm: 400}}>
                <Paper elevation={2} >
                  <Box
                    width={{xs: '100%', sm: 400}}
                    pt={4}
                    pb={4}
                    px={4}
                  >

                    <Typography variant='h5' align='center'>
                      Admin Panel
                    </Typography>
                    <Typography color='textSecondary'
                      variant='body2' align='center'>
                      Enter Admin email to add Admin
                    </Typography>

                    <Box p={2}>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        variant='outlined'
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={
                          formik.touched.email && formik.errors.email
                        }
                      />
                    </Box>

                    {formik.isSubmitting &&
                      <Box p={2} display='flex' justifyContent='center' >
                        <CircularProgress /> </Box>}

                    {alertMessage &&
                      <Snackbar
                        open={alertSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setAlertSnackbar(false)}
                      >
                        {isErrorMessage ?
                          <Alert severity={'error'}>{alertMessage}</Alert> :
                          <Alert severity={'success'}>{alertMessage}</Alert>
                        }

                      </Snackbar>
                    }


                    <Box p={2}>
                      <Button
                        fullWidth
                        color='primary'
                        variant='contained'
                        type='submit'
                      >
                        + add Admin
                      </Button>
                    </Box>


                    <Box p={2}>
                      <Divider />
                    </Box>

                    <Box display='flex' justifyContent='center'>
                      <Link component={RouterLink} to='/'>
                        <Typography variant='body1'>
                          go back home
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
          </form>
        </div> :
        <Typography>
          innsufficent permissions
        </Typography>}
    </>
  );
};


export default AdminPanel;
