import React from 'react';
import {withStyles} from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import UnpaidEntry from './UnpaidEntry';

const styles = () => ({
  root: {
  },
});

interface Props{
  costs: Array <{
    id: string,
    description: string,
    cost: number,
    date: Date,
    datepaid?: Date
  }>,
  checkedUnpaid: Array <{
    id: string,
    description: string,
    cost: number,
    date: Date,
  }>,
  day: string,
  addChecked: (id: string) => void,
  removeChecked: (id: string) => void
}

class UnpaidDayCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);


    this.state = {

    };
  }

  render() {
    const {costs, day, checkedUnpaid, addChecked, removeChecked} = this.props;


    return (
      <Paper elevation={2}>
        <Box
          my={2}
          p={2}
          color="text.primary"
          display="flex"
          flexDirection="column"
        >
          <Typography>
            {day}
          </Typography>
          {costs.map(( entry) => (
            <UnpaidEntry
              key={entry.id}
              entry={entry}
              checkedUnpaid={checkedUnpaid}
              addChecked={addChecked}
              removeChecked={removeChecked}
            />

          ))}
        </Box>
      </Paper>
    );
  }
}

export default withStyles(styles)(UnpaidDayCard);
