import i18n from 'i18next';
import i18nBackend from 'i18next-http-backend';
import {initReactI18next} from 'react-i18next';


i18n
    .use(initReactI18next)
    .use(i18nBackend)
    .init({
      fallbackLng: 'en',
      // lng: 'en',
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
      backend: {
        loadPath: `/locales/{{lng}}.json`,
      },
    });


export default i18n;
