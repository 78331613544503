import * as React from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from '@material-ui/data-grid';
import {useLocation} from 'react-router-dom';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
interface IHistory {
  data: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
    datepaid?: Date
  }>
}

const TableView: React.FC = () => {
  const location = useLocation();
  const columns = [
    {field: 'description', headerName: 'Description', width: 150},
    {field: 'cost', headerName: 'Cost', width: 150},
    {field: 'date', headerName: 'Date', width: 150},
    {field: 'datepaid', headerName: 'Paid on', width: 150},
  ];

  // type assertion
  const stateData = location.state as IHistory;

  return (
    <div style={{height: '100vh', width: '100%'}}>
      <DataGrid
        columns={columns}
        rows={stateData.data}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

export default TableView;
