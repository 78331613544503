import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import CurrencyField, {handleValueChange} from './misc/CurrencyField';
import CostAPI from '../api/CostAPI';

interface Props {
    entry: {
    id: string,
    description: string,
    cost: number,
    date: Date,
    datepaid?: Date
  },
  setIsEditing: (value: boolean) => void
}

const validationSchema = yup.object({
  description: yup
      .string()
      .required('Description is required'),
});

const EditEntry: React.FC<Props> = (props) => {
  const {entry, setIsEditing} = props;
  const [alertMessage] = useState('');
  const [isErrorMessage] = useState(true);
  const [alertSnackbar, setAlertSnackbar] = useState(false);

  const formik = useFormik({
    initialValues: {
      description: entry.description,
      cost: entry.cost,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const editedEntry = Object.assign({}, entry);
      editedEntry.description = values.description;
      editedEntry.cost = values.cost;

      await CostAPI.getAPI().updateUnpaid(editedEntry);
      setIsEditing(false);
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Box
          display='flex'
          flexDirection='column'
          py={1}
        >
          <Box p={1}>
            <TextField
              fullWidth
              id="description"
              name="description"
              label="Description"
              variant='outlined'
              value={formik.values.description}
              onChange={formik.handleChange}
              error={
                formik.touched.description &&
                      Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description
              }
              disabled={formik.isSubmitting}
            />
          </Box>

          <Box p={1}>
            <CurrencyField
              fullWidth
              label="Amount"
              name="cost"
              value={formik.values.cost}
              error={
                formik.touched.cost &&
                      Boolean(formik.errors.cost)
              }
              helperText={
                formik.touched.cost && formik.errors.cost
              }
              disabled={formik.isSubmitting}
              onValueChange={
                handleValueChange('cost', formik.setFieldValue)
              }
            />
          </Box>

          {formik.isSubmitting &&
                    <Box p={1} display='flex' justifyContent='center' >
                      <CircularProgress /> </Box>}

          {alertMessage &&
                  <Snackbar
                    open={alertSnackbar}
                    autoHideDuration={4000}
                    onClose={() => setAlertSnackbar(false)}
                  >
                    {isErrorMessage ?
                        <Alert severity={'error'}>{alertMessage}</Alert> :
                        <Alert severity={'success'}>{alertMessage}</Alert>
                    }

                  </Snackbar>
          }
          <Box display='flex' flexDirection='row'>
            <Box p={1} width='100%'>
              <Button
                fullWidth
                color='primary'
                variant='outlined'
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
            </Box>

            <Box p={1} width='100%'>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                type='submit'
              >
                Save
              </Button>
            </Box>
          </Box>

        </Box>
      </form>
    </div>
  );
};

export default EditEntry;
