import {Navigate, Outlet} from 'react-router-dom';
import {useAuth} from './contexts/AuthContext';
import React from 'react';

const PrivateRoute: React.FC = () => {
  const {currentUser} = useAuth();

  return currentUser ? <Outlet /> : <Navigate to="/login" replace />;
};

export default PrivateRoute;
