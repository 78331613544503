import React, {PropsWithChildren} from 'react';
import {withStyles, WithStyles} from '@material-ui/styles';
import CostDashboard from '../CostDashboard';
import UnpaidCosts from '../UnpaidCosts';
import PaidCosts from '../PaidCosts';
import CostAPI from '../../api/CostAPI';
import MyAppBar from '../UI/MyAppBar';

const styles = () => ({});

interface State {
  paid: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
    datepaid?: Date
  }>,
  unpaid: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
  }>,
  checkedUnpaid: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
  }>,
  paidTotal: number,
  unpaidTotal: number,
}

type Props = PropsWithChildren<WithStyles<typeof styles>>

class BaldyKosten extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      unpaid: [],
      checkedUnpaid: [],
      paid: [],
      paidTotal: 0,
      unpaidTotal: 0,
    };
  }
  componentDidMount() {
    CostAPI.getAPI().getUnpaid((data: State['unpaid']) =>
      this.setState({
        unpaid: data,
        checkedUnpaid: data,
      }),
    );
    CostAPI.getAPI().getPaid((data: State['paid']) =>
      this.setState({paid: data}));
    // TODO
    // CostAPI.getAPI().getData((paidTotal: number) => {
    //   this.setState({paidTotal: paidTotal});
    // });
  }

  addChecked = (id: string) => {
    const addedValue = this.state.unpaid.find((element) => element.id === id);
    if (addedValue) {
      this.setState((prevState: State) => ({
        checkedUnpaid: [...prevState.checkedUnpaid, addedValue],
      }));
    }
  };

  removeChecked = (id: string) => {
    this.setState((prevState: State) => ({
      checkedUnpaid: prevState.checkedUnpaid.filter((element) =>
        element.id !== id,
      ),
    }));
  };

  render() {
    const {unpaid, paid, checkedUnpaid, paidTotal} = this.state;

    return (
      <>
        <MyAppBar unpaid={unpaid} paid={paid} title={'Kosten'}/>

        <CostDashboard
          unpaid={unpaid}
          paid={paid}
          checkedUnpaid={checkedUnpaid}
          paidTotal={paidTotal}
        />

        {unpaid.length > 0 &&
         <UnpaidCosts
           unpaid={unpaid}
           checkedUnpaid={checkedUnpaid}
           addChecked={this.addChecked}
           removeChecked={this.removeChecked}
         />
        }
        {paid.length > 0 && <PaidCosts paid={paid}/> }
      </>
    );
  }
}
const StyledBaldyKosten = withStyles(styles)(BaldyKosten);
export default StyledBaldyKosten as React.FC;

