import React from 'react';
import {Box, Button, Menu, MenuItem} from '@material-ui/core';

import {useTranslation} from 'react-i18next';
import {LANGUAGES} from '../../constants';
import LanguageIcon from '@material-ui/icons/Language';


const LanguagePicker: React.FC = () => {
  const {i18n} = useTranslation();

  const [languagePickerAnchor, setLanguagePickerAnchor] =
    React.useState<null | HTMLElement>(null);

  const handleLanguageSelect = (lang: string) => () => {
    i18n.changeLanguage(lang);
    closeLanguagePicker();
  };

  const openLanguagePicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguagePickerAnchor(event.currentTarget);
  };

  const closeLanguagePicker = () => {
    setLanguagePickerAnchor(null);
  };


  return (
    <Box >
      <Button
        color="primary"
        aria-label="language-menu"
        onClick={openLanguagePicker}
        endIcon={<LanguageIcon />}
      >
        {i18n.language}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={languagePickerAnchor}
        keepMounted
        open={Boolean(languagePickerAnchor)}
        onClose={closeLanguagePicker}
      >
        {LANGUAGES.map((lang) => (
          <MenuItem key={lang.code} onClick={handleLanguageSelect(lang.code)}>
            {lang.label}
          </MenuItem>
        ))}

      </Menu>
    </Box>
  );
};

export default LanguagePicker;
