import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {Menu, MenuItem} from '@material-ui/core';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../contexts/AuthContext';
import LanguagePicker from './LanguagePicker';


interface Props {
  paid?: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
    datepaid?: Date
  }>,
  unpaid?: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
    datepaid?: Date
  }>,
  title: string
}

const MyAppBar: React.FC<Props> = (props: Props) => {
  const [simpleMenuAnchor, setSimpleMenuAnchor] =
    React.useState<null | HTMLElement>(null);
  const {logout, currentUser} = useAuth();
  const navigate = useNavigate();
  const {title} = props;

  const closeSimpleMenu = () => {
    setSimpleMenuAnchor(null);
  };

  function openSimpleMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setSimpleMenuAnchor(event.currentTarget);
  }

  function onLogout() {
    closeSimpleMenu();
    logout();
  }

  function onLogin() {
    closeSimpleMenu();
    navigate('/login');
  }

  function onAdmin() {
    navigate('/admin-panel');
  }

  function onTableView() {
    closeSimpleMenu();
    const tableData = props.unpaid.concat(props.paid);
    navigate('/table-view', {state: {data: tableData}});
  }


  return (
    <AppBar position="sticky" style={{boxShadow: 'none'}}>
      <Box bgcolor="background.paper" color="text.primary">
        <Toolbar>
          <Box p={1}>
            <IconButton edge="start" color="primary" aria-label="menu">
              <MenuIcon />
            </IconButton>
          </Box>

          <Box flexGrow={1} p={1}>
            <Typography variant="h6">{title}</Typography>
          </Box>

          <LanguagePicker/>

          {/* Menu*/}
          <Box >
            <IconButton
              edge="end"
              color="primary"
              aria-label="simple-menu"
              onClick={openSimpleMenu}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={simpleMenuAnchor}
              keepMounted open={Boolean(simpleMenuAnchor)}
              onClose={closeSimpleMenu}>
              {props.paid && props.unpaid && currentUser &&
                <MenuItem onClick={onTableView}>Table View & Export</MenuItem>}
              {currentUser ?
                <div>
                  <MenuItem onClick={onAdmin}>Admin Panel</MenuItem>
                  <MenuItem onClick={onLogout}>Logout</MenuItem>
                </div> :
                <MenuItem onClick={onLogin}>Login</MenuItem>
              }
            </Menu>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>);
};

export default MyAppBar;
