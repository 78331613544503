import React, {useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {NumericFormat} from 'react-number-format';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CostAPI from '../api/CostAPI';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import {Link} from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
  root: {
  },
  sticky: {
    position: 'sticky',
    top: 64,
    zIndex: 1,
  },
});


interface Props {
  paid: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
    datepaid?: Date
  }>,
  unpaid: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
  }>,
  checkedUnpaid: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
  }>,
  paidTotal: number,
}

const CostDashboard: React.FC<Props> = (props) => {
  const [unpaidTotal, setUnpaidTotal] = useState(0);
  const [paidTotal, setPaidTotal] = useState(0);
  const [checkedUnpaidTotal, setCheckedUnpaidTotal] = useState(0);
  const [buyDialog, setBuyDialog] = useState(false);
  const [collapse, setCollapse] = React.useState(true);
  const classes = useStyles();

  useEffect(() => {
    let scrollPosition = 0;
    // ADDED
    const pageHeight = document.body.offsetHeight;
    const viewportHeight = window.innerHeight;

    function handleScroll() {
      const newScrollPosition = window.scrollY;

      if (newScrollPosition === scrollPosition) {
        return;
      }
      // REVISED
      if (newScrollPosition < 0 ||
        newScrollPosition + viewportHeight > pageHeight) {
        return;
      }
      const shouldShow = newScrollPosition < scrollPosition;

      Math.abs(newScrollPosition - scrollPosition) > viewportHeight / 100 &&
        setCollapse(shouldShow);

      scrollPosition = newScrollPosition;
    }

    window.addEventListener('scroll', handleScroll, {passive: true});
  }, []);

  function calculateUnpaidTotal() {
    let total = 0;
    for (const x of props.unpaid) {
      total = x.cost + total;
    }
    return total;
  }

  function calculatePaidTotal() {
    let total = 0;
    if (props.paid !== undefined) {
      for (const x of props.paid) {
        total = x.cost + total;
      }
    }
    return total;
  }

  function calculateCheckedUnpaidTotal() {
    let total = 0;
    for (const x of props.checkedUnpaid) {
      total = x.cost + total;
    }
    return total;
  }

  function closeBuyDialog() {
    setBuyDialog(false);
  }

  function handleBuyDialog() {
    CostAPI.getAPI().setPaid(props.checkedUnpaid);
    closeBuyDialog();
  }

  function onPay() {
    setBuyDialog(true);
  }

  useEffect(() => {
    setUnpaidTotal(calculateUnpaidTotal());
    setPaidTotal(calculatePaidTotal());
    setCheckedUnpaidTotal(calculateCheckedUnpaidTotal());
  });

  return (
    <div className={classes.sticky}>
      <Paper elevation={2}>

        {/* Dashboard*/}
        <Box
          p={2}
          bgcolor="background.paper"
          color="text.primary"
        >
          {/* Unbezahlt*/}
          <Box
            display="flex"
            mb={1.2}
          >
            <Box flexGrow={1} >
              <Typography color="textPrimary" variant="h5">
                Unpaid
              </Typography>
            </Box>
            <Box >
              <Typography variant="h5">
                <NumericFormat
                  value={unpaidTotal}
                  displayType={'text'}
                  decimalSeparator={','}
                  thousandSeparator={'.'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  suffix={' €'}
                />
              </Typography>
            </Box>
          </Box>

          <Collapse timeout={500} in={collapse} >
            {/* Bezahlt*/}
            <Box
              display="flex"
              mb={1.2}
            >
              <Box flexGrow={1} >
                <Typography variant="subtitle2">
                  Paid
                </Typography>
              </Box>
              <Box >
                <Typography variant="subtitle2">
                  <NumericFormat
                    value={paidTotal}
                    displayType={'text'}
                    decimalSeparator={','}
                    thousandSeparator={'.'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={' €'}
                  />
                </Typography>
              </Box>
            </Box>

            {/* Gesamt*/}
            <Box
              display="flex"
              mb={1.2}
            >
              <Box flexGrow={1} >
                <Typography variant="subtitle2">
                  Total
                </Typography>
              </Box>
              <Box >
                <Typography variant="subtitle2">
                  <NumericFormat
                    value={paidTotal + unpaidTotal}
                    displayType={'text'}
                    decimalSeparator={','}
                    thousandSeparator={'.'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={' €'}
                  />
                </Typography>
              </Box>
            </Box>

          </Collapse>

          <Divider />

          {/* anzahl ausgewählt */}
          <Box
            display="flex"
            alignItems="center"
            mb={1.2}
            mt={1.2}
          >
            <Box flexGrow={1} >
              <Typography variant="subtitle2">
                {props.checkedUnpaid.length} selected
              </Typography>
            </Box>
            <Box >
              <Typography variant="h6">
                <NumericFormat
                  value={checkedUnpaidTotal}
                  displayType={'text'}
                  decimalSeparator={','}
                  thousandSeparator={'.'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  suffix={' €'}
                />
              </Typography>
            </Box>
          </Box>

          {/* Buttons */}
          <Box display='flex' flexDirection='row'>
            <Box p={1} width='100%'>

              <Link to='/add-cost' >
                <Button color="primary" variant="outlined" fullWidth>
                  <Typography variant="button">
                    + Add Entry
                  </Typography>
                </Button>
              </Link>
            </Box>
            <Box p={1} width='100%'>
              <Button color="primary" variant="contained" fullWidth
                onClick={onPay}>
                <Typography color="textPrimary" variant="button">
                  Pay
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Dialog
        open={buyDialog}
        onClose={closeBuyDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Did you pay?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to mark the selected as paid?<br /><br />
            Total: <NumericFormat
              value={checkedUnpaidTotal}
              displayType={'text'}
              decimalSeparator={','}
              thousandSeparator={'.'}
              decimalScale={2}
              fixedDecimalScale={true}
              suffix={' €'}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box pb={2} >
            <Button onClick={closeBuyDialog} color="primary">
              No, pay later
            </Button>
          </Box>
          <Box pb={2} pr={2} >
            <Button onClick={handleBuyDialog}
              color="primary" variant="contained" autoFocus>
              Yes, I did Pay
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};


export default CostDashboard;
