import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {NumericFormat} from 'react-number-format';
import EditEntry from './EditEntry';
import {Menu, MenuItem, Typography} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CostAPI from '../api/CostAPI';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

interface Props {
  entry: {
    id: string,
    description: string,
    cost: number,
    date: Date,
    datepaid?: Date
  }
  checkedUnpaid: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
  }>,
  addChecked: (id: string) => void,
  removeChecked: (id: string) => void
}

const UnpaidEntry: React.FC<Props> = (props) => {
  const {entry, checkedUnpaid, addChecked, removeChecked} = props;
  const [simpleMenuAnchor, setSimpleMenuAnchor] =
    React.useState<null | HTMLElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editDialog, setEditDialog] = useState(false);

  const closeSimpleMenu = () => {
    setSimpleMenuAnchor(null);
  };

  function openSimpleMenu(event: React.MouseEvent<HTMLButtonElement>) {
    setSimpleMenuAnchor(event.currentTarget);
  }

  function handleChange() {
    if (typeof checkedUnpaid.find(
        (element) => element.id === entry.id,
    ) === 'object') {
      removeChecked(entry.id);
    } else {
      addChecked(entry.id);
    }
  }

  function closeEditDialog() {
    setEditDialog(false);
  }

  function handleEditDialog() {
    CostAPI.getAPI().deleteUnpaid(entry);
    closeEditDialog();
  }

  return (
    <>
      {isEditing ? <EditEntry entry={entry} setIsEditing={setIsEditing} /> :
        <Box
          pt={1}
          display="flex"
          flexDirection="row"
        // justifyContent="space-between"
        >

          <Box alignSelf="center" flexGrow={1} overflow="hidden">
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    typeof checkedUnpaid.find(
                        (element) => element === entry,
                    ) === 'object'
                  }
                  onChange={handleChange}
                  name="checkedB"
                  color="primary"
                />
              }
              label={entry.description}
            />
          </Box>

          <Box alignSelf="center" whiteSpace="nowrap">
            <Typography>
              <NumericFormat
                value={entry.cost}
                displayType={'text'}
                decimalSeparator={','}
                thousandSeparator={'.'}
                decimalScale={2}
                fixedDecimalScale={true}
                suffix={' €'}
              />
            </Typography>


          </Box>

          <Box alignSelf="right" >
            <IconButton
              edge="end"
              color="primary"
              aria-label="simple-menu"
              onClick={openSimpleMenu}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={simpleMenuAnchor}
              keepMounted
              open={Boolean(simpleMenuAnchor)}
              onClose={closeSimpleMenu}
            >
              <MenuItem onClick={() => {
                setSimpleMenuAnchor(null);
                setIsEditing(true);
              }}>Edit</MenuItem>
              <MenuItem onClick={() =>
                setEditDialog(true)}>Delete</MenuItem>
            </Menu>
          </Box>

          <Dialog
            open={editDialog}
            onClose={closeEditDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Are you sure you want to delete the entry?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Description: {entry.description} <br />
                Total: <NumericFormat
                  value={entry.cost}
                  displayType={'text'}
                  decimalSeparator={','}
                  thousandSeparator={'.'}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  suffix={' €'}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Box pb={2} >
                <Button onClick={closeEditDialog} color="primary">
                  Cancel
                </Button>
              </Box>
              <Box pb={2} pr={2} >
                <Button onClick={handleEditDialog}
                  color="primary" variant="contained" autoFocus>
                  Delete
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </Box>
      }
    </>
  );
};

export default UnpaidEntry;
