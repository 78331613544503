import React, { Suspense } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import theme from "./theme";
import CssBaseline from "@material-ui/core/CssBaseline";

// import PropTypes from 'prop-types';
import BaldyKosten from "./components/pages/BaldyKosten";
import SignUp from "./components/pages/SignUp";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";
import ForgotPassword from "./components/pages/ForgotPassword";
import AddCost from "./components/pages/AddCost";
import TableView from "./components/pages/TableView";
import Login from "./components/pages/Login";
import AdminPanel from "./components/pages/AdminPanel";
import LanguageProvider from "./provider/LanguageProvider";
import CV from "./components/pages/CV";

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback="Loading...">
        <CssBaseline />
        <LanguageProvider>
          <Router basename={process.env.PUBLIC_URL}>
            <AuthProvider>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/curriculum-vitae" element={<CV />} />
                <Route path="/cv" element={<CV />} />
                <Route path="/" element={<PrivateRoute />}>
                  <Route path="/" element={<BaldyKosten />} />
                  <Route path="/add-cost" element={<AddCost />} />
                  <Route path="/table-view" element={<TableView />} />
                  <Route path="/admin-panel" element={<AdminPanel />} />
                </Route>
              </Routes>
            </AuthProvider>
          </Router>
        </LanguageProvider>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
