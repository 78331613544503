import React from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import logo from "../../images/logo/new/R-Logo.svg";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { GitHub } from "@material-ui/icons";
import portrait from "../../images/raphael_mitas.jpg";
import LanguagePicker from "../UI/LanguagePicker";

type Props = {
  companySpecificText?: string;
  companyLogo?: string;
  isColabLogo?: boolean;
  companyCVDownloadLocation?: string;
};
const CVMobile: React.FC<Props> = ({
  companySpecificText,
  companyLogo,
  companyCVDownloadLocation,
}) => {
  const { t } = useTranslation();
  const symbols = [",", "’", "\\.", "\\?", "!", ":", ";", "\n"];
  const symbolsCheck = [",", "’", ".", "?", "!", ":", ";", "\n"];

  const formatText = (text: string) => {
    const chunks = text.split(new RegExp(`(${symbols.join("|")})`));
    const formattedText = chunks.map((chunk, index: React.Key) => {
      if (chunk === "\n") {
        return <br key={index} />;
      } else if (symbolsCheck.includes(chunk)) {
        return (
          <Typography
            key={index}
            display="inline"
            variant="inherit"
            color="primary"
          >
            <b>{chunk}</b>
          </Typography>
        );
      } else {
        return (
          <Typography variant="inherit" display="inline" key={index}>
            {chunk}
          </Typography>
        );
      }
    });
    return formattedText;
  };

  const formattedIntroduction = () => {
    const text: string = t("cv.introduction");

    const formattedText = formatText(text);
    return formattedText;
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = t(
      `cv.${
        companyCVDownloadLocation
          ? companyCVDownloadLocation
          : "download_location"
      }`
    );
    link.href = t(
      `cv.${
        companyCVDownloadLocation
          ? companyCVDownloadLocation
          : "download_location"
      }`
    );
    link.click();
  };

  return (
    <>
      <Stack>
        <Box
          sx={{
            height: "100vh",
            position: "relative",
          }}
        >
          <Box
            style={{
              backgroundImage: `url(${portrait})`,
              backgroundSize: "auto 101% ",
              opacity: "40%",
              height: "100%",
              width: "100%",
              position: "absolute",
            }}
          />

          {/* HI I'm Raphael */}
          <Box
            display="flex"
            flexDirection="column"
            alignContent={"center"}
            justifyContent="center"
            p={3}
            width="100%"
            height="100%"
            position="absolute"
          >
            <Typography variant="h2">
              <b>{formattedIntroduction()}</b>
            </Typography>

            <Box pt={20}>
              <Typography
                variant="h4"
                color="primary"
                display="inline"
              ></Typography>
              <Typography variant="h4" display="inline">
                {formatText("B.Sc. " + t("cv.job_title"))}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* ABOUT ME */}
        <Paper elevation={0} square>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
              p: 3,
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              height={100}
              mb={3}
            >
              <img
                src={companyLogo ? companyLogo : logo}
                alt="logo"
                height="inherit"
                width="auto"
              />
            </Box>

            <Stack
              direction="column"
              divider={<Divider orientation="horizontal" flexItem />}
              spacing={1}
              alignItems={"center"}
            >
              <Typography align="justify">
                {formatText(
                  t(
                    `cv.${
                      companySpecificText ? companySpecificText : "about_me"
                    }`
                  )
                )}
              </Typography>
              <Box
                sx={{
                  pt: 2,
                }}
              >
                <Button
                  color="primary"
                  variant="outlined"
                  aria-label="download cv"
                  onClick={onDownload}
                >
                  {t("cv.download_cv")}
                </Button>
              </Box>

              <Stack
                direction="row"
                width="100%"
                justifyContent="space-evenly"
                aria-label="call-to-action"
              >
                <IconButton aria-label="call" href="tel:+4915202146138">
                  <PhoneIcon />
                </IconButton>
                <IconButton
                  aria-label="email"
                  href="mailto:raphael.mitas@web.de"
                >
                  <EmailIcon />
                </IconButton>
                <IconButton
                  aria-label="LinkedIn"
                  href="https://www.linkedin.com/in/raphael-mitas-5144b510a/"
                  target="_blank"
                >
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  aria-label="GitHub"
                  href="https://github.com/RaphaelMitas/"
                  target="_blank"
                >
                  <GitHub />
                </IconButton>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </Stack>

      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          p: 2,
        }}
      >
        <LanguagePicker />
      </Box>
    </>
  );
};

export default CVMobile;
