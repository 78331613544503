import React, {useState} from 'react';
import {Field, Form, Formik, FormikErrors} from 'formik';
import {Box, Button, CircularProgress} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import {TextField} from 'formik-material-ui';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {Link as RouterLink} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {useAuth} from '../../contexts/AuthContext';
import Link from '@material-ui/core/Link';

const ForgotPassword: React.FC = () => {
  const {loading, resetPassword} = useAuth();
  const [alertMessage, setAlertMessage] = useState('');
  const [isErrorMessage, setIsErrorMessage] = useState(true);
  const [alertSnackbar, setAlertSnackbar] = useState(false);
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validate={(values) => {
        const errors: FormikErrors<{ email: string }> = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={async (values) => {
        try {
          await resetPassword(values.email);
          setIsErrorMessage(false);
          setAlertMessage(
              'Email for password reset has been successfully sent',
          );
        } catch {
          setIsErrorMessage(true);
          setAlertMessage(
              'Email for password reset has not been sent, try again',
          );
        } finally {
          setAlertSnackbar(true);
        }
      }}
    >

      {({submitForm}) => (
        <Form>
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            height='100vh'
          >
            <Box width={{xs: '100%', sm: 400}}>
              <Paper elevation={2} >
                <Box
                  width={{xs: '100%', sm: 400}}
                  pt={4}
                  pb={4}
                  px={4}
                >
                  <Typography variant='h5' align='center'>
                    Trouble Logging In?
                  </Typography>
                  <Typography color='textSecondary'
                    variant='body2' align='center'>
                      Enter your email.
                      I&apos;ll send you a link to reset your password.
                  </Typography>

                  <Box p={2}>
                    <Field
                      component={TextField}
                      fullWidth
                      label='Email'
                      variant='outlined'
                      name='email'
                    />
                  </Box>

                  {loading &&
                    <Box p={2} display='flex' justifyContent='center' >
                      <CircularProgress/> </Box>}

                  {alertMessage &&
                  <Snackbar
                    open={alertSnackbar}
                    autoHideDuration={4000}
                    onClose={() => setAlertSnackbar(false)}
                  >
                    {isErrorMessage ?
                        <Alert severity={'error'}>{alertMessage}</Alert> :
                        <Alert severity={'success'}>{alertMessage}</Alert>
                    }

                  </Snackbar>
                  }


                  <Box p={2}>
                    <Button
                      fullWidth
                      color='primary'
                      variant='contained'
                      disabled={loading}
                      onClick={submitForm}
                    >
                      reset password
                    </Button>
                  </Box>


                  <Box p={2}>
                    <Divider/>
                  </Box>

                  <Box display='flex' justifyContent='center' >
                    <Typography variant='body1' >
                      Don&apos;t have an Account?&nbsp;
                      <Link component={RouterLink} to='/signup'>
                        Sign Up
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
