import React from 'react';
import {withStyles} from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import {NumericFormat} from 'react-number-format';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = () => ({
  root: {
  },
});

interface Props {
  costs: Array<{
    description: string,
    cost: number,
    date: Date,
    datepaid?: Date
  }>,
  day: string
}
class PaidDayCard extends React.Component<Props> {
  constructor(props: Props) {
    super(props);


    this.state = {

    };
  }


  render() {
    const {costs, day} = this.props;


    return (
      <Paper elevation={2}>

        <Box
          my={2}
          p={2}
          color="text.primary"
          display="flex"
          flexDirection="column"
        >
          <Typography>
            {day}
          </Typography>
          {costs.length > 0 && Object.keys(costs).map((key, value) => (
            <Box
              key={value}
              pt={1}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >

              <Box overflow="hidden" >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={true}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label={costs[value].description}
                />
              </Box>

              <Box alignSelf="center" whiteSpace="nowrap">
                <Typography>
                  <NumericFormat
                    value={costs[value].cost}
                    displayType={'text'}
                    decimalSeparator={','}
                    thousandSeparator={'.'}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    suffix={' €'}
                  />
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Paper>
    );
  }
}


export default withStyles(styles)(PaidDayCard);
