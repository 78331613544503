import 'fontsource-roboto';
import {createTheme} from '@material-ui/core/styles';


const theme = createTheme({

  palette: {
    type: 'dark',
    primary: {
      light: '#70d4eb',
      main: '#4DCAE6',
      dark: '#358da1',
      contrastText: '#FFFFFF',
    },
    background: {
      paper: '#424242',
      default: '#303030',
    },
    text: {
      primary: 'rgba(255, 255, 255, 0.87)',
      secondary: 'rgba(186, 186, 186, 0.54)',
      disabled: 'rgba(186, 186, 186, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',

    },
  },

  typography: {
    fontFamily: 'Roboto',
    fontSize: 14,

    h1: {
      fontWeight: 700,
      color: '#4DCAE6',
    },

  },
  shape: {
    borderRadius: 8,
  },


});

export default theme;
