import React, {useState} from 'react';
import {InputAdornment, TextField} from '@material-ui/core';
import {NumericFormat} from 'react-number-format';

export const handleValueChange = (name, setFieldValue) => (val) =>
  setFieldValue(name, val.floatValue);

const CurrencyField = ({...props}) => {
  const [displayValue, setDisplayValue] = useState();
  return (
    <NumericFormat
      customInput={TextField}
      variant="outlined"
      decimalSeparator={','}
      thousandSeparator={'.'}
      value={displayValue}
      decimalScale={2}
      onValueChange={(vals) => setDisplayValue({value: vals.formattedValue})}
      InputProps={{
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
      }}
      {...props}
    />
  );
};


export default CurrencyField;
