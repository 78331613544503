import React from 'react';
import {withStyles} from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import dateFormat from 'dateformat';
import PaidDayCard from './PaidDayCard';

const styles = () => ({
  root: {
  },
});

interface Props {
  paid: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
    datepaid?: Date
  }>,
}

interface State {
  groupedPaid: {
        [key: number]: {
          [key: string]: Props['paid']
        }
      },
  paidExpanded: boolean
}

class PaidCosts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      paidExpanded: false,
      groupedPaid: {},
    };
  }

  componentDidMount() {
    this.groupByDay();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props) {
      this.groupByDay();
    }
  }


  groupByDay() {
    const paid = this.props.paid;
    interface tPaid {
      id: string,
      description: string,
      cost: number,
      date: Date,
      datepaid?: Date
    }
      interface tYearPaid {
        [key: number]: Props['paid']
      }

      const costByYear = paid.reduce((acc: tYearPaid, value: tPaid) => {
        // Group initialization
        if (!acc[value.date.getFullYear()]) {
          acc[value.date.getFullYear()] = [];
        }

        // Grouping
        acc[value.date.getFullYear()].push(value);

        return acc;
      }, {});


      interface tDayPaid {
        [key: string]: Props['paid']
      }

      const costByDayAndYear: State['groupedPaid'] = {};
      for (const [key, v] of Object.entries(costByYear)) {
        costByDayAndYear[+key] = v.reduce((acc: tDayPaid, value: tPaid) => {
          // Group initialization
          if (!acc[dateFormat(value.date, 'dd. mmmm')]) {
            acc[dateFormat(value.date, 'dd. mmmm')] = [];
          }

          // Grouping
          acc[dateFormat(value.date, 'dd. mmmm')].push(value);

          return acc;
        }, {});
      }

      this.setState({groupedPaid: costByDayAndYear});
  }


  render() {
    const {paidExpanded, groupedPaid} = this.state;


    return (
      <Box
        m={2}
        color="text.primary"
        display="flex"
        flexDirection="column"
      >
        <IconButton
          edge="start"
          onClick={() => this.setState({paidExpanded: !paidExpanded})}
        >
          {paidExpanded ? <ExpandMoreIcon/> : <ChevronRightIcon/>}
          <Box pl={1}>

            <Typography>
              bezahlt
            </Typography>
          </Box>

        </IconButton>


        <Collapse in={paidExpanded}>

          {Object.keys(groupedPaid).reverse().map((keyDay, value) => (
            <Box key={value} p={1}>
              <Typography key={value} align="center">
                {new Date().getFullYear() === +keyDay ? '' : keyDay}
              </Typography>
              {Object.keys(groupedPaid[+keyDay]).map((key, value) => (
                <PaidDayCard
                  key={value}
                  day={key}
                  costs={groupedPaid[+keyDay][key]}
                />

              ))
              }
            </Box>
          ))

          }
        </Collapse>
      </Box>
    );
  }
}


export default withStyles(styles)(PaidCosts);
