import React, {useState} from 'react';
import {Field, Form, Formik, FormikErrors} from 'formik';
import Paper from '@material-ui/core/Paper';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {Box, Button, CircularProgress} from '@material-ui/core';
import CurrencyField, {handleValueChange} from '../misc/CurrencyField';
import CostAPI from '../../api/CostAPI';
import {useNavigate} from 'react-router-dom';
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker} from 'formik-material-ui-pickers';
import MuiTextField from '../misc/MuiTextField';
// import {DatePicker} from 'formik-material-ui-pickers';


const AddCost: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertSnackbar, setAlertSnackbar] = useState(false);
  const navigate = useNavigate();


  interface IValues {
    description: string;
    cost: string;
  }

  return (
    <>
      <Formik
        initialValues={{
          cost: '',
          description: '',
          date: new Date(),
        }}
        validate={(values) => {
          const errors: FormikErrors<IValues> = {};
          if (!values.description) {
            errors.description = 'Required';
          } else if (values.description.length < 3) {
            errors.description =
              'description has to be longer than 3 characters';
          } else if (values.description.length > 50) {
            errors.description =
              'description can\'t be longer than 50 characters';
          }
          if (!values.cost) {
            errors.cost = 'Required';
          } else if (+values.cost > 10000000000) {
            errors.cost = 'come on it wasn\'t that expensive!';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          try {
            setLoading(true);
            await CostAPI.getAPI().addUnpaid(values);
            navigate('/');
          } catch (e: unknown) {
            if (e instanceof Error) {
              setAlertMessage(e.message);
            } else {
              setAlertMessage('An unknown error occurred');
            }
            setAlertSnackbar(true);
          } finally {
            setLoading(false);
          }
        }}
      >

        {({
          submitForm,
          errors,
          values,
          setFieldValue,
        }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Form>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                height='100vh'
              >
                <Box width={{xs: '100%', sm: 400}}>
                  <Paper elevation={2} >
                    <Box
                      width={{xs: '100%', sm: 400}}
                      pt={4}
                      pb={4}
                      px={4}
                      justifyItems='center'
                    >
                      <Box p={2}>
                        <MuiTextField
                          fullWidth
                          name='description'
                          label='Description'
                          variant='outlined'
                          type='text'
                        />
                      </Box>
                      <Box p={2}>
                        <CurrencyField
                          fullWidth
                          label="Amount"
                          name="cost"
                          value={values.cost}
                          error={!!errors.cost}
                          helperText={errors.cost}
                          disabled={loading}
                          onValueChange={
                            handleValueChange('cost', setFieldValue)
                          }
                        />
                      </Box>
                      <Box p={2}>
                        <Field
                          fullWidth
                          component={DatePicker}
                          name='date'
                          label='Date'
                          format='dd.MM.yyyy'
                          inputVariant='outlined'
                        />
                      </Box>


                      {alertMessage &&
                        <Snackbar
                          open={alertSnackbar}
                          autoHideDuration={4000}
                          onClose={() => setAlertSnackbar(false)}
                        >
                          <Alert severity="error">{alertMessage}</Alert>
                        </Snackbar>
                      }

                      {loading &&
                        <Box p={2} display='flex' justifyContent='center' >
                          <CircularProgress /> </Box>}

                      <Box p={2}>
                        <Button
                          fullWidth
                          color='primary'
                          variant='contained'
                          disabled={loading}
                          onClick={submitForm}
                        >
                          + add entry
                        </Button>
                      </Box>

                    </Box>

                  </Paper>
                </Box>


              </Box>
            </Form>
          </MuiPickersUtilsProvider>
        )}
      </Formik>

    </>
  );
};

export default AddCost;
