import React from 'react';
import {withStyles} from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import dateFormat from 'dateformat';
import UnpaidDayCard from './UnpaidDayCard';

const styles = () => ({
  root: {
  },
});
interface Props {
  unpaid: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
  }>,
  checkedUnpaid: Array<{
    id: string,
    description: string,
    cost: number,
    date: Date,
  }>,
  addChecked: (id: string) => void,
  removeChecked: (id: string) => void
}

interface State {
  groupedUnpaid: {
        [key: number]: {
          [key: string]: Props['unpaid']
        }
      },
  unpaidExpanded: boolean
}

class UnpaidCosts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      unpaidExpanded: true,
      groupedUnpaid: {},
    };
  }

  componentDidMount() {
    this.groupByDay();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps !== this.props) {
      this.groupByDay();
    }
  }


  groupByDay() {
    const unpaid = this.props.unpaid;
    interface tUnpaid {
      id: string,
      description: string,
      cost: number,
      date: Date,
      datepaid?: Date
    }
    interface tYearUnpaid {
        [key: number]: Props['unpaid']
      }

    const costByYear = unpaid.reduce((acc: tYearUnpaid, value:tUnpaid) => {
      // Group initialization
      if (!acc[value.date.getFullYear()]) {
        acc[value.date.getFullYear()] = [];
      }

      // Grouping
      acc[value.date.getFullYear()].push(value);

      return acc;
    }, {});


      interface tDayUnaid {
        [key: string]: Props['unpaid']
      }

      const costByDayAndYear: State['groupedUnpaid'] = {};
      for (const [key, v] of Object.entries(costByYear)) {
        costByDayAndYear[+key] = v.reduce((acc: tDayUnaid, value: tUnpaid) => {
        // Group initialization
          if (!acc[dateFormat(value.date, 'dd. mmmm')]) {
            acc[dateFormat(value.date, 'dd. mmmm')] = [];
          }

          // Grouping
          acc[dateFormat(value.date, 'dd. mmmm')].push(value);

          return acc;
        }, {});
      }

      this.setState({groupedUnpaid: costByDayAndYear});
  }


  render() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const {unpaidExpanded, groupedUnpaid} = this.state;


    return (
      <Box
        m={2}
        color="text.primary"
        display="flex"
        flexDirection="column"
      >
        <IconButton
          edge="start"
          onClick={() => this.setState({unpaidExpanded: !unpaidExpanded})}
        >
          {unpaidExpanded ? <ExpandMoreIcon/> : <ChevronRightIcon/>}
          <Box pl={1}>

            <Typography>
                            unbezahlt
            </Typography>
          </Box>

        </IconButton>


        <Collapse in={unpaidExpanded}>

          {Object.keys(groupedUnpaid).reverse().map((keyDay, value) => (
            <Box key={value} p={1}>
              <Typography key={value} align="center">
                {new Date().getFullYear() === +keyDay ? '' : keyDay}
              </Typography>
              {Object.keys(groupedUnpaid[+keyDay]).map((key, value) => (
                <UnpaidDayCard
                  key={value}
                  day={key}
                  costs={groupedUnpaid[+keyDay][key]}
                  addChecked={this.props.addChecked}
                  removeChecked={this.props.removeChecked}
                  checkedUnpaid={this.props.checkedUnpaid}
                />

              ))
              }
            </Box>
          ))

          }
        </Collapse>
      </Box>
    );
  }
}


export default withStyles(styles)(UnpaidCosts);
