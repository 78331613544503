import {
  getFirestore,
  doc,
  collection,
  writeBatch,
  query,
  onSnapshot,
  orderBy,
  deleteDoc,
  addDoc,
  updateDoc,
} from 'firebase/firestore';
import CostBO from './CostBO';
import app from './firebase';

const db = getFirestore(app);

export default class CostAPI {
  static api = null;

  static getAPI() {
    if (this.api == null) {
      this.api = new CostAPI();
    }
    return this.api;
  }

  async setPaid(data) {
    const batchProcess = writeBatch(db);
    const paidCollectionRef = collection(db, 'family', 'costs', 'paid');

    data.forEach((element) => {
      const unpaidRef = doc(db, 'family', 'costs', 'unpaid', element.id);
      const paidRef = doc(paidCollectionRef); // Firestore generate an unused ID

      // Modify the element
      element.datepaid = new Date();
      delete element.id;

      // Convert to plain object
      const plainElement = Object.assign({}, element);

      // Add operations to batch
      batchProcess.delete(unpaidRef);
      batchProcess.set(paidRef, plainElement);
    });

    // Commit the batch
    await batchProcess.commit();
  }


  async addUnpaid(element) {
    const unpaidCollectionRef = collection(db, 'family', 'costs', 'unpaid');
    return addDoc(unpaidCollectionRef, element);
  }

  updateUnpaid(element) {
    const ref = doc(db, 'family/costs/unpaid', element.id);
    delete element.id;
    element = Object.assign({}, element);
    return updateDoc(ref, element);
  }

  deleteUnpaid(element) {
    const ref = doc(db, 'family/costs/unpaid', element.id);
    return deleteDoc(ref);
  }

  getUnpaid(setData) {
    const queryProcess = query(
        collection(db, 'family/costs/unpaid'), orderBy('date', 'desc'),
    );
    onSnapshot(queryProcess, (querySnapshot) => {
      const data = [];
      querySnapshot.docs.forEach((doc) =>
        data.push(CostBO.fromJSON(doc.data())[0].setID(doc.id)),
      );
      setData(data);
    }, (err) => console.log(`Encountered error: ${err}`));
  }

  getPaid(setData) {
    const queryProcess = query(
        collection(db, 'family/costs/paid'), orderBy('date', 'desc'),
    );
    onSnapshot(queryProcess, (querySnapshot) => {
      const data = [];
      querySnapshot.docs.forEach((doc) =>
        data.push(CostBO.fromJSON(doc.data())[0].setID(doc.id)),
      );
      setData(data);
    }, (err) => console.log(`Encountered error: ${err}`));
  }
}
