
export default class CostBO {
  constructor() {
    this.cost = 0;
    this.date = new Date();
    this.datepaid = '';
    this.description = '';
    this.favorite = '';
    this.ispaid = '';
    this.quantity = '';
    this.id = '111';
  }

  setID(id) {
    this.id = id;
    return this;
  }

  setDate(date) {
    this.date = date;
    return this;
  }

  setDatepaid(datepaid) {
    this.datepaid = datepaid;
    return this;
  }

  static fromJSON(units) {
    const result = [];

    if (Array.isArray(units)) {
      for (const g of units) {
        Object.setPrototypeOf(g, CostBO.prototype);

        result.push(g);
      }
    } else {
      // Für ein singuläres Objekt
      const g = units;
      Object.setPrototypeOf(g, CostBO.prototype);

      g.setDate(g.date.toDate());
      g.datepaid && g.setDatepaid(g.datepaid.toDate());

      result.push(g);
    }


    return result;
  }
}
