import React, {useEffect} from 'react';
import {I18nextProvider} from 'react-i18next';
import i18n from '../config/i18n';

const LanguageProvider: React.FC = ({children}) => {
  useEffect(() => {
    const language = navigator.language;
    i18n.changeLanguage(language);
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      {children}
    </I18nextProvider>
  );
};

export default LanguageProvider;
