import React, { useEffect, useRef, useState } from "react";
import { IParallax, Parallax, ParallaxLayer } from "@react-spring/parallax";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import portrait from "../../images/raphael_mitas.jpg";
import work from "../../images/work.jpg";
import logo from "../../images/logo/new/R-Logo.svg";
import hdm from "../../images/hdm.png";
import straightRoad from "../../images/straight_road.jpg";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import theme from "../../theme";
import { red } from "@material-ui/core/colors";
import { useTranslation } from "react-i18next";
import LanguagePicker from "../UI/LanguagePicker";
import { animated, useSpring, config } from "@react-spring/web";
import Stack from "@mui/material/Stack";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { GitHub } from "@material-ui/icons";
import CVMobile from "./CVMobileView";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: "56.25%", // 16:9
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    avatar: {
      backgroundColor: red[500],
    },
  })
);

const AnimatedArrow = () => {
  const styles = useSpring({
    loop: true,
    to: [
      { opacity: 1, y: -30 },
      { opacity: 0, y: 0 },
    ],
    from: { opacity: 0, y: 0 },
    config: { duration: 1500, ...config.molasses },
  });

  return (
    <animated.div
      style={{
        ...styles,
        position: "absolute",
        bottom: "0%",
        left: "50%",
        transform: "translateX(-50%)",
        width: "70px",
        height: "70px",
        alignItems: "bottom",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M19 14l-7 7m0 0l-7-7m7 7V3" />
      </svg>
    </animated.div>
  );
};

type Props = {
  companySpecificText?: string;
  companyLogo?: string;
  isColabLogo?: boolean;
  companyCVDownloadLocation?: string;
};
const CV: React.FC<Props> = ({
  companySpecificText,
  companyLogo,
  isColabLogo,
  companyCVDownloadLocation,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const parallax = useRef<IParallax>(null);

  const scroll = (to: number) => {
    if (parallax.current) {
      parallax.current.scrollTo(to);
    }
  };

  const classes = useStyles();
  const { t } = useTranslation();
  const symbols = [",", "’", "\\.", "\\?", "!", ":", ";", "\n"];
  const symbolsCheck = [",", "’", ".", "?", "!", ":", ";", "\n"];

  const formatText = (text: string) => {
    const chunks = text.split(new RegExp(`(${symbols.join("|")})`));
    const formattedText = chunks.map((chunk, index: React.Key) => {
      if (chunk === "\n") {
        return <br key={index} />;
      } else if (symbolsCheck.includes(chunk)) {
        return (
          <Typography
            key={index}
            display="inline"
            variant="inherit"
            color="primary"
          >
            <b>{chunk}</b>
          </Typography>
        );
      } else {
        return (
          <Typography variant="inherit" display="inline" key={index}>
            {chunk}
          </Typography>
        );
      }
    });
    return formattedText;
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = t(
      `cv.${
        companyCVDownloadLocation
          ? companyCVDownloadLocation
          : "download_location"
      }`
    );
    link.href = t(
      `cv.${
        companyCVDownloadLocation
          ? companyCVDownloadLocation
          : "download_location"
      }`
    );
    link.click();
  };

  const formattedIntroduction = () => {
    const text: string = t("cv.introduction");

    const formattedText = formatText(text);
    return formattedText;
  };

  const [backgroundSize, setBackgroundSize] = useState("100% auto");

  useEffect(() => {
    // Function to update background size
    const updateBackgroundSize = () => {
      const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
      const aspectRatio = windowWidth / windowHeight;

      if (aspectRatio > 16 / 9) {
        setBackgroundSize("101% auto");
      } else {
        setBackgroundSize("auto 101%");
      }
    };

    // Initial call to set the background size
    updateBackgroundSize();

    // Adding event listener for window resize
    window.addEventListener("resize", updateBackgroundSize);

    // Cleanup: Removing event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateBackgroundSize);
    };
  }, []);

  return isMobile ? (
    <CVMobile
      companySpecificText={companySpecificText}
      companyCVDownloadLocation={companyCVDownloadLocation}
      companyLogo={companyLogo}
    />
  ) : (
    <>
      {/* <MyAppBar title={'Raphaël Mitas / Full-Stack Developer'}/>*/}

      <Parallax ref={parallax} pages={2} style={{ top: "0", left: "0" }}>
        {/* Hintergrund Bild 1 */}
        <ParallaxLayer
          factor={1}
          offset={0}
          speed={0}
          style={{
            backgroundImage: `url(${portrait})`,
            backgroundSize: backgroundSize,
            opacity: "40%",
          }}
        />

        <ParallaxLayer factor={1} offset={0} speed={-3}>
          <AnimatedArrow />
        </ParallaxLayer>

        {/* IM RAPHAEL...*/}
        <ParallaxLayer
          offset={0}
          speed={0.5}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => scroll(1)}
        >
          <Box
            display="flex"
            flexDirection="column"
            p={1}
            width={{
              xs: "100%",
              md: "50%",
            }}
          >
            <Typography variant="h2">
              <b>{formattedIntroduction()}</b>
            </Typography>

            <Box pt={20}>
              <Typography
                variant="h4"
                color="primary"
                display="inline"
              ></Typography>
              <Typography variant="h4" display="inline">
                {formatText("B.Sc. " + t("cv.job_title"))}
              </Typography>
            </Box>
          </Box>
        </ParallaxLayer>
        {/* Hintergrund Bild 2 */}
        <ParallaxLayer
          factor={1}
          offset={1}
          speed={0}
          style={{
            backgroundImage: `url(${work})`,
            backgroundSize: backgroundSize.replace("101%", "117%"),
            opacity: "30%",
          }}
        />

        {/* ABOUT ME */}
        <ParallaxLayer factor={1} offset={1} speed={0.5}>
          <Box display="flex" justifyContent="center" height="100%">
            <Box
              display="flex"
              alignItems="center"
              width={{
                xs: "100%",
                md: "1000px",
              }}
              height="100%"
            >
              <Paper elevation={0} square>
                <Box
                  display="flex"
                  justifyContent="center"
                  pt={5}
                  bgcolor="background.paper"
                  height="100%"
                >
                  {companyLogo && isColabLogo ? (
                    <img src={companyLogo} alt="companyLogo" height={100} />
                  ) : (
                    <>
                      <img src={logo} alt="logo" height={100} />
                      {companyLogo ? (
                        <>
                          <Box px={4} marginY="auto">
                            <img
                              src={"X Colab with blue.svg"}
                              alt="X"
                              height={40}
                            />
                          </Box>
                          <img
                            src={companyLogo}
                            alt="companyLogo"
                            height={100}
                          />
                        </>
                      ) : null}
                    </>
                  )}
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  p={8}
                  flexDirection="column"
                >
                  <Typography align="justify">
                    {formatText(
                      t(
                        `cv.${
                          companySpecificText ? companySpecificText : "about_me"
                        }`
                      )
                    )}
                  </Typography>
                  <Stack
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    pt={8}
                    alignItems={"center"}
                  >
                    <Box px={2}>
                      <Button
                        color="primary"
                        variant="outlined"
                        aria-label="download cv"
                        onClick={onDownload}
                      >
                        {t("cv.download_cv")}
                      </Button>
                    </Box>

                    <Stack direction="row" aria-label="call-to-action">
                      <IconButton aria-label="call" href="tel:+4915202146138">
                        <PhoneIcon />
                      </IconButton>
                      <IconButton
                        aria-label="email"
                        href="mailto:raphael.mitas@web.de"
                      >
                        <EmailIcon />
                      </IconButton>
                      <IconButton
                        aria-label="LinkedIn"
                        href="https://www.linkedin.com/in/raphael-mitas-5144b510a/"
                        target="_blank"
                      >
                        <LinkedInIcon />
                      </IconButton>
                      <IconButton
                        aria-label="GitHub"
                        href="https://github.com/RaphaelMitas/"
                        target="_blank"
                      >
                        <GitHub />
                      </IconButton>
                    </Stack>
                  </Stack>
                </Box>
              </Paper>
            </Box>

            {/* <Box display='flex' alignItems='center' height={'100%'} p={2}>

              <Paper elevation={0} square >
                <Stack p={5}>
                  <Typography >
                  Raphaël Torsten Mitas
                  </Typography>
                  <Typography >
                  Lessingstr. 4
                  </Typography>
                  <Typography >
                  64291 Darmstadt
                  </Typography>
                  <Typography >
                  Mobil: +49 1520 2146138
                  </Typography>
                  <Typography >
                  E-Mail: raphael.mitas@web.de
                  </Typography>
                </Stack>
              </Paper>

            </Box> */}
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          factor={2}
          offset={2}
          speed={0}
          style={{
            backgroundImage: `url(${straightRoad})`,
            backgroundSize: "100% auto",
            opacity: "30%",
          }}
        />

        <ParallaxLayer offset={2.25} speed={0.5}>
          <Box
            display="flexbox"
            flexDirection="column"
            justifyContent="flex-end"
            width="100%"
          >
            <Box width={600}>
              <Card>
                <CardHeader
                  avatar={<Avatar>S</Avatar>}
                  action={
                    <IconButton aria-label="settings">
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title="Stuttgart Media University"
                  subheader="Computer Science & Business Administration
                / 2018 - present "
                />
                <CardMedia
                  className={classes.media}
                  image={hdm}
                  title="Paella dish"
                />
                <CardContent>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </Typography>
                </CardContent>
                <CardActions disableSpacing>
                  <Box display="flex" flexDirection="row" width="100%">
                    <Box flexGrow={1}>
                      <Button color={"primary"}>learn more</Button>
                    </Box>
                    <IconButton aria-label="show more">
                      <ExpandMoreIcon />
                    </IconButton>
                    <Box></Box>
                  </Box>
                </CardActions>
              </Card>
            </Box>
          </Box>
        </ParallaxLayer>

        <ParallaxLayer
          factor={1}
          offset={0}
          speed={-1}
          style={{ height: "10%" }}
        >
          <Box display="flex" justifyContent="flex-end" p={2}>
            <LanguagePicker />
          </Box>
        </ParallaxLayer>
      </Parallax>
    </>
  );
};

export default CV;
