import React, {useContext, useEffect, useState} from 'react';
import {auth} from '../api/firebase';
import PropTypes from 'prop-types';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  UserCredential,
  User,
} from 'firebase/auth';
import firebase from 'firebase/compat/app';

interface IContext {
  currentUser: ExtendedUser | null,
  loading: boolean,
  resetPassword: (email: string) => Promise<void> | null,
  login: (email: string, password: string) =>
    Promise<UserCredential> | null,
  signup: (email: string, password: string) =>
    Promise<UserCredential> | null,
  logout: () => Promise<void> | null,
}

interface ExtendedIdTokenResult extends firebase.auth.IdTokenResult {
  admin?: boolean
}
interface ExtendedUser extends User {
  admin?: boolean
}

const AuthContext = React.createContext<IContext>({
  currentUser: null,
  loading: false,
  resetPassword: () => Promise.resolve(),
  login: () => Promise.resolve(null),
  signup: () => Promise.resolve(null),
  logout: () => Promise.resolve(),
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider: React.FC = ({children}) => {
  const [currentUser, setCurrentUser] = useState<ExtendedUser | null>(null);
  const [loading, setLoading] = useState(true);

  async function signup(email: string, password: string) {
    setLoading(true);
    const result = await createUserWithEmailAndPassword(auth, email, password);
    setLoading(false);
    return result;
  }

  async function login(email: string, password: string) {
    setLoading(true);
    const result = await signInWithEmailAndPassword(auth, email, password);
    setLoading(false);
    return result;
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email: string) {
    return sendPasswordResetEmail(auth, email);
  }

  useEffect(() => {
    return onAuthStateChanged(auth, (user: ExtendedUser) => {
      if (user) {
        user.getIdTokenResult().then((idTokenResult: ExtendedIdTokenResult) => {
          user.admin = idTokenResult.claims.admin;
          setCurrentUser(user);
        });
      } else {
        setCurrentUser(user);
      }

      setLoading(false);
    });
  }, []);

  const value: IContext =
    {currentUser, loading, resetPassword, login, signup, logout};

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
